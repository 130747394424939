<script lang="ts">
	import { Background } from "@components/Background.js";
	import Section from "@components/Section.svelte";
	import Formatted from "@components/common/Formatted.svelte";
	import Icon from "@components/common/Icon.svelte";
	import Link from "@components/common/Link.svelte";
	import { ButtonVariant } from "@components/common/getButtonClass.js";
	import Layout from "@components/layout/Layout.svelte";
	import arrowNext from "@core/assets/icons/arrow-next.svg?raw";
	import { getHomepagePath } from "@core/schema/paths/getHomepagePath.js";
	import { getGlobalTemplateProps } from "../contexts/getGlobalTemplateProps.js";
	import type { NotFoundProps } from "./NotFoundProps.js";

	const { tenant } = getGlobalTemplateProps();
	const _: NotFoundProps = $props();
</script>

<Layout>
	<Section background={Background.LightGradient} centered gridHeadline="container-6" gridDesc="container-6" hero>
		{#snippet headline()}
			<Formatted text="Stránka nenalezena" />
		{/snippet}
		{#snippet desc()}
			<p>
				<Formatted
					text="Je nám líto, ale požadovaná stránka nebyla nalezena. Ujistěte se, že jste neudělali chybu v URL adrese. Je možné, že byla stránka přemístěna nebo odstraněna."
				/>
			</p>
		{/snippet}
		{#snippet cta()}
			<Link
				variant={ButtonVariant.PhloxOutlineLightBg}
				class="flex items-center justify-center mx-auto mt-2"
				href={getHomepagePath(tenant)}
			>
				<Formatted text="Začít od hlavní strany" />
				<Icon icon={arrowNext} class="ml-2.5 text-fstgrey-900 w-5"></Icon>
			</Link>
		{/snippet}
	</Section>
</Layout>
